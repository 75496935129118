import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import useClasses from "../useClasses";
import { API_GATEWAY_URL, getToday } from "../utils";
import { StyledTableCell } from "../components/StyledTableComponents";
import axios from "axios";
import Notification from "../components/Notification";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const styles = (theme) => ({
  resultContainer: {
    marginTop: "2em",
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  table: {
    backgroundColor: "#f3f6f8",
    color: "white",
    "&::before, &::after": {
      borderColor: "red",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "20px",
    },
    overflow: "hidden",
  },
  tableContainer: {
    boxShadow: "0px 0px 30px 2px rgba(212, 212, 212, 0.3)",
    borderRadius: "8px",
    width: "10%",
  },
  tableRowHeader: {
    border: "none",
    whiteSpace: "nowrap",
    backgroundColor: "#f3f6f8",
  },
  tableHeaderText: {
    color: "#637381",
    fontFamily: "AirbnbCereal-Medium",
    textAlign: "left",
    fontSize: "12px",
  },
});

const SupplierNameTable = ({
  searchResults,
  totalCount,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  onUpdateSearchResults,
  onDeleteSearchResults,
}) => {
  const [isDeleteMode, setIsDeleteMode] = useState(false); // Track is user performing delete
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const classes = useClasses(styles);
  const [editIndex, setEditIndex] = useState(-1); // Track the index of the editing row
  const [editedData, setEditedData] = useState(""); // Store edited data for the row

  const handleSnackBar = (sev, msg) => {
    setSeverity(sev);
    setMessage(msg);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  // Function to handle row deletion
  const handleDeleteRow = async (searchResults, index) => {
    await axios
      .delete(`${API_GATEWAY_URL}/supplier`, {
        data: {
          deletedRowData: searchResults[index],
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setEditedData("");
          return response.data;
        }
      })
      .catch((error) => {
        console.error("Failed to delete row data", error);
        throw error;
      });
  };

  // Function to handle row update
  const updateRowData = async (updatedRowData, searchResults) => {
    await axios
      .patch(`${API_GATEWAY_URL}/supplier`, {
        updatedRowData,
        originalRowData: searchResults[editIndex],
      })
      .then((response) => {
        if (response.status === 200) {
          setEditedData("");
          return response.data;
        }
      })
      .catch((error) => {
        console.error("Failed to update row data", error);
        throw error;
      });
  };

  const handleSave = () => {
    // Compare original row data with edited data to check for changes
    const isChanged = Object.keys(editedData).some(
      (key) => editedData[key] !== searchResults[editIndex][key]
    );
    if (isChanged) {
      updateRowData(editedData, searchResults)
        .then(() => {
          handleSnackBar(
            "success",
            "The supplier list has been successfully updated!"
          );
          onUpdateSearchResults(editIndex, editedData);
          setEditIndex(-1);
        })
        .catch((error) => {
          handleSnackBar("error", "Error!");
          console.error("Failed to update row:", error);
        });
      setEditIndex(-1);
    } else {
      handleSnackBar(
        "warning",
        "No changes made to the data. Please update the fields if you want to save any changes."
      );
      console.log("No changes detected, save cancelled.");
      setEditIndex(-1);
    }
  };

  const handleFieldChange = (e) => {
    setEditedData(e.target.value);
  };

  const handleCancel = () => {
    setEditIndex(-1);
    setEditedData("");
  };

  const handleEdit = (index, row) => {
    setIsDeleteMode(false);
    setEditIndex(index);
    setEditedData(row);
    console.log(row);
  };

  const handleDelete = (index, row) => {
    setIsDeleteMode(true);
    setEditIndex(index);
    setEditedData(row);
    handleDeleteRow(searchResults, index)
      .then(() => {
        handleSnackBar(
          "success",
          "The supplier price list has been successfully deleted!"
        );
        onDeleteSearchResults(index);
        setEditIndex(-1);
      })
      .catch((error) => {
        handleSnackBar(
          "error",
          "Failed to delete the supplier price list. Please try again."
        );
        console.error("Failed to delete row", error);
      });
  };

  const handlePageChange = (event, newPage) => {
    onPageChange(event, newPage);
    setEditIndex(-1);
    setEditedData({});
  };

  return (
    <Box className={classes.resultContainer}>
      <Notification
        close={handleCloseSnackbar}
        open={openSnackbar}
        message={message}
        severity={severity}
      />
      <TableContainer
        className={classes.tableContainer}
        style={{ maxWidth: "800px", marginLeft: "0" }}
      >
        <Table>
          <TableHead>
            <TableRow className={classes.tableRowHeader}>
              <TableCell style={{ paddingLeft: "30px" }}>Supplier</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResults.map((result, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: "#fff",
                  border: "#f3f6f8 dotted 2px",
                }}
              >
                <StyledTableCell align="left">
                  {editIndex === index && !isDeleteMode ? (
                    <TextField
                      value={editedData ?? result}
                      onChange={(e) => handleFieldChange(e)}
                      size="small"
                    />
                  ) : (
                    result
                  )}
                </StyledTableCell>
                <StyledTableCell className={classes.table_cell} align="left">
                  {editIndex === index ? (
                    <>
                      <IconButton onClick={handleSave} color="primary">
                        <SaveOutlinedIcon />
                      </IconButton>
                      <IconButton onClick={handleCancel} color="secondary">
                        <CancelOutlinedIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEdit(index, result)}>
                        <EditNoteOutlinedIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(index, result)}>
                        <DeleteOutlineOutlinedIcon color="error" />
                      </IconButton>
                    </>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Box>
  );
};

export default SupplierNameTable;
