import React, { useCallback, useState, useEffect } from "react";
import { Box, Grid, CircularProgress, Backdrop } from "@mui/material";
import axios from "axios";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  validateSession,
} from "../utils";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import SupplierPriceTable from "../components/SupplierPriceTable";
import { StyledHeader } from "../components/StyledHeader";
import { StyledTextField } from "../components/StyledTextField";
import _debounce from "lodash/debounce";
import {
  StyledGreenButton,
  StyledOrangeButton,
} from "../components/StyledButtons";

const SupplierPriceList = () => {
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [mpn, setMpn] = useState("");
  const [supplier, setsupplier] = useState("");
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

  function handleDebounceFn(mpn, supplier, page) {
    setOpen(true);
    axios
      .get(`${API_GATEWAY_URL}/supplier/price-list`, {
        params: {
          creator: Cookies.get("employee", null),
          role: Cookies.get("role", "SALES"),
          mpn: mpn ? mpn : "",
          supplier: supplier ? supplier : "",
          page: page ? page : 1,
          page_size: 10,
        },
      })
      .then((response) => {
        setResults(response.data.price_list);
        setSearchResults(response.data.price_list);
        setTotalCount(response.data.total);
        setPage(page - 1);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setOpen(false);
      });
  }

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();
    debounceFn(null, null, 1);
  }, []);

  const filterSearch = (mpn, supplier) => {
    setMpn(mpn.trim());
    setsupplier(supplier.toUpperCase());
    debounceFn(mpn, supplier, 1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleDebounceFn(mpn, supplier, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateSearchResults = (index, updatedRow) => {
    setSearchResults((prevResults) =>
      prevResults.map((row, i) => (i === index ? updatedRow : row))
    );
  };

  const deleteSearchResult = (index) => {
    setSearchResults((prevResults) =>
      prevResults.filter((_, i) => i !== index)
    );
  };

  return (
    <Box sx={{ padding: "0em 1em", fontFamily: "AirbnbCereal-Medium" }}>
      <StyledHeader>Supplier Price List</StyledHeader>
      {/* Flex container for aligning inputs and button */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        {/* MPN Input */}
        <Grid container spacing={2}>
          <Grid item lg={3}>
            {" "}
            {/* Set a fixed width for the input */}
            <StyledTextField
              placeholder={"MPN"}
              value={mpn}
              onChange={(e) => filterSearch(e.target.value, supplier)}
              InputProps={{
                sx: {
                  color: "#212b36",
                  fontFamily: "AirbnbCereal-Book",
                  fontSize: "1em",
                },
              }}
            />
          </Grid>
          <Grid item lg={3}>
            {/* Supplier Input */} {/* Set a fixed width for the input */}
            <StyledTextField
              placeholder={"Supplier"}
              value={supplier}
              onChange={(e) => filterSearch(mpn, e.target.value)}
              InputProps={{
                sx: {
                  color: "#212b36",
                  fontFamily: "AirbnbCereal-Book",
                  fontSize: "1em",
                },
              }}
            />
          </Grid>
          <Grid item lg={2} />
          <Grid item lg={2}>
            <Box sx={{ marginLeft: "auto" }}>
              <StyledGreenButton
                onClick={() => {
                  navigate("/price-list");
                }}
              >
                Upload Price List
              </StyledGreenButton>
            </Box>
          </Grid>
          <Grid item lg={2}>
            <Box sx={{ marginLeft: "auto" }}>
              <StyledOrangeButton
                onClick={() => {
                  navigate("/supplier/price-list/add/");
                }}
              >
                Add New Entry
              </StyledOrangeButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <SupplierPriceTable
        searchResults={searchResults}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onUpdateSearchResults={updateSearchResults}
        onDeleteSearchResults={deleteSearchResult}
      />
      <Backdrop open={open} sx={{ color: "#fff", zIndex: 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default SupplierPriceList;
