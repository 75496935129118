import React, { useCallback, useState } from "react";
import {
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Paper,
  TableContainer,
  Box,
  TextField,
  TablePagination,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Popover,
} from "@mui/material";
import { FileCopy } from "@mui/icons-material";
import { useEffect } from "react";
import axios from "axios";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  validateSession,
} from "../utils";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom/";
import * as XLSX from "xlsx";
import useClasses from "../useClasses";
import { StyledHeader } from "../components/StyledHeader";
import { StyledTextField } from "../components/StyledTextField";
import { StyledSelect } from "../components/StyledSelect";
import {
  StyledGreenButton,
  StyledDropDownButton,
} from "../components/StyledButtons";
import { StyledTableCell } from "../components/StyledTableComponents";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import _debounce from "lodash/debounce";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const styles = (theme) => ({
  // necessary for content to be below app bar
  header: {
    fontFamily: "AirbnbCereal-Bold",
    fontSize: "1.5rem",
    color: "#212b36",
    padding: "1em 0em 0.5em 0em",
  },
  text_field: {
    width: "300px",
    transitionDuration: "0.6s",
    padding: "0px 1px 0px 1px",
    margin: "10px 2px 2px 2px",
    backgroundColor: "#fff",
    "&:hover fieldset": {
      borderColor: "#212b36",
      transitionDuration: "0.6s",
    },
    "&:focus": {
      borderColor: "#212b36",
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "50px",
    },
  },
  text_font: {
    paddingLeft: "10px",
    fontFamily: "AirbnbCereal-Book",
    fontSize: "13px",
  },

  // results - 2nd half
  resultContainer: {
    margin: "2em 0em",
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  tableContainer: {
    boxShadow: "0px 0px 30px 2px rgba(212, 212, 212, 0.3)",
    borderRadius: "8px",
    width: "80%",
  },
  table: {
    backgroundColor: "#f3f6f8",
    color: "white",
    "&::before, &::after": {
      borderColor: "red",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "20px",
    },
    overflow: "hidden",
  },
  tableRowHeader: {
    border: "none",
    whiteSpace: "nowrap",
  },
  tableHeaderText: {
    color: "#637381",
    fontFamily: "AirbnbCereal-Medium",
    textAlign: "left",
    fontSize: "12px",
  },
  tableText: {
    color: "#212b36",
    fontFamily: "AirbnbCereal-Book",
    padding: "8px 10px",
    fontSize: "12px",
  },
  tableRow: {
    backgroundColor: "#fff",
    borderColor: "#f3f6f8",
    borderStyle: "dotted",
  },
  tableCellIconWrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "150px",
    justifyContent: "flex-end",
  },
  pdf: {
    color: "#212b36",
    borderRadius: "none",
  },
  excel: {
    color: "#01a76e",
    borderRadius: "none",
  },

  // pop over
  popoverContainer: {
    "& .MuiPopover-paper": {
      width: "150px", // Set the desired width
      borderRadius: "8px",
      boxShadow: "rgba(145, 158, 171, 0.24) 0px 0px 20px 0px",
    },
  },
  popoverButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    padding: "10px 0px 10px 15px",
    color: "#212b36",
  },
  popoverText: {
    marginLeft: "5px",
    textTransform: "capitalize",
    fontFamily: "AirbnbCereal-Book",
    fontSize: "13px",
  },
  popoverBoxider: {
    border: "none",
    borderBottomStyle: "dashed",
    borderColor: "rgba(145, 158, 171, 0.2)",
    borderWidth: "0px 0px thin",
  },
});

const GetContact = () => {
  const classes = useClasses(styles);
  let navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const [company, setCompany] = useState("");
  const [type, setType] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [exportAll, setExportAll] = useState("");

  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);

  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

  const handleClick = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMoreAnchorEl(null);
  };

  const moreOpen = Boolean(moreAnchorEl);
  const moreId = moreOpen ? "simple-popover" : undefined;

  function handleDebounceFn(company, type, contact, email, page, exportAll) {
    setOpen(true);
    return axios
      .get(`${API_GATEWAY_URL}/contact/paginate`, {
        params: {
          creator: Cookies.get("employee", null),
          role: Cookies.get("role", "SALES"),
          company: company ? company : "",
          type: type ? type : "",
          contact: contact ? contact : "",
          email: email ? email : "",
          page: page ? page : 1,
          page_size: 10,
          ...(exportAll == "true" && { export_all: exportAll }),
        },
      })
      .then((response) => {
        let contact_list = [];
        let contacts = response.data.contacts;
        setTotalCount(response.data.total);
        contacts.sort((a, b) => {
          let nameA = a.company.toUpperCase(); // ignore upper and lowercase
          let nameB = b.company.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1; // nameA comes first
          }
          if (nameA > nameB) {
            return 1; // nameB comes first
          }
          return 0; // names must be equal
        });
        for (let contact of contacts) {
          contact_list.push({
            contact_name: contact.contact_name,
            company: contact.company,
            department: contact.department,
            office_number: contact.office_number,
            extension: contact.extension,
            phone: contact.phone,
            email: contact.email,
            remarks: contact.remarks,
            company_mandarin: contact.company_mandarin,
            type: contact.type,
            office_address: contact.office_address,
            payment_terms: contact.payment_terms,
            shipment_terms: contact.shipment_terms,
            account_manager: contact.account_manager,
            position: contact.position,
          });
        }
        setResults(contact_list);
        setSearchResults(contact_list);
        setPage(page - 1);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setOpen(false);
      });
  }

  useEffect(() => {
    if (exportAll === "true") {
      exportExcel();
    }
  }, [exportAll]);

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();
    debounceFn(null, null, null, null, 1, false);
  }, []);

  const exportExcel = () => {
    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new();
    let line_items = [];
    for (let i = 0; i < searchResults.length; i++) {
      // format into correct row
      let line_item = results[i];
      let row = {
        "Contact Name": line_item.contact_name,
        Company: line_item.company,
        "Company (中文)": line_item.company_mandarin,
        Department: line_item.department,
        "Office Address": line_item.office_address,
        "Office Number": line_item.office_number,
        Extension: line_item.extension,
        Phone: line_item.phone,
        Email: line_item.email,
        Remarks: line_item.remarks,
        Type: line_item.type,
        "Payment Terms": line_item.payment_terms,
        "Shipment Terms": line_item.shipment_terms,
        "Account Manager": line_item.account_manager,
      };
      line_items.push(row);
    }

    const ws = XLSX.utils.json_to_sheet(line_items);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the workbook
    XLSX.writeFile(wb, `export.xlsx`);
  };

  const exportExcelAll = async () => {
    await handleDebounceFn(company, type, contact, email, 1, "true");
    setExportAll("true");
    await handleDebounceFn(company, type, contact, email, 1, "");
    setExportAll("");
  };

  const filterSearch = (company, type, contact, email) => {
    setCompany(company);
    setType(type);
    setContact(contact);
    setEmail(email);
    debounceFn(company, type, contact, email, 1, exportAll);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // make call for next page
    handleDebounceFn(company, type, contact, email, newPage + 1, exportAll);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        padding: "0em 1em",
        fontFamily: "AirbnbCereal-Medium",
      }}
    >
      <Grid
        sx={{ padding: "1em 0em 0.5em 0em" }}
        container
        xs={12}
        lg={12}
        spacing={2}
      >
        <Grid item xs={6} lg={6}>
          <StyledHeader style={{ padding: 0 }}>Contacts</StyledHeader>
        </Grid>
        <Grid item xs={6} lg={6}>
          {Cookies.get("role", "SALES") == "ADMIN" && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                color: "red",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <IconButton aria-describedby={moreId} onClick={handleClick}>
                <MoreHorizIcon sx={{ color: "#252b32" }} />
              </IconButton>
              <Popover
                id={moreId}
                open={moreOpen}
                anchorEl={moreAnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box sx={{ borderRadius: "8px" }}>
                  <Box sx={{ width: "100%" }}>
                    <StyledDropDownButton
                      startIcon={
                        <FilterListOutlinedIcon style={{ fontSize: "14px" }} />
                      }
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        textAlign: "left",
                        fontFamily: "AirbnbCereal-Book",
                        textTransform: "none",
                        letterSpacing: 0,
                        fontSize: "15px",
                      }}
                      onClick={() => exportExcel()}
                    >
                      export current page
                    </StyledDropDownButton>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <StyledDropDownButton
                      startIcon={
                        <PostAddOutlinedIcon style={{ fontSize: "14px" }} />
                      }
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        textAlign: "left",
                        fontFamily: "AirbnbCereal-Book",
                        textTransform: "none",
                        letterSpacing: 0,
                        fontSize: "15px",
                      }}
                      onClick={exportExcelAll}
                    >
                      export all records
                    </StyledDropDownButton>
                  </Box>
                </Box>
              </Popover>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container xs={12} lg={12} spacing={2}>
        <Grid item xs={12} lg={2}>
          <StyledTextField
            placeholder={"Company name (EN/CN)"}
            value={company}
            fullWidth={true}
            onChange={(e) => filterSearch(e.target.value, type, contact, email)}
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <StyledTextField
            placeholder={"Type"}
            value={type}
            fullWidth={true}
            onChange={(e) =>
              filterSearch(company, e.target.value, contact, email)
            }
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <StyledTextField
            placeholder={"Contact"}
            value={contact}
            fullWidth={true}
            onChange={(e) => filterSearch(company, type, e.target.value, email)}
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <StyledTextField
            placeholder={"Email"}
            value={email}
            fullWidth={true}
            onChange={(e) =>
              filterSearch(company, type, contact, e.target.value)
            }
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
      </Grid>
      <Box className={classes.resultContainer}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowHeader}>
                <TableCell style={{ paddingLeft: "30px" }}>Company</TableCell>
                <TableCell>Company (中文)</TableCell>
                <TableCell>Office Address</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Office Number</TableCell>
                <TableCell>Extension</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Payment Terms</TableCell>
                <TableCell>Shipment Terms</TableCell>
                <TableCell>Type</TableCell>
                <TableCell style={{ paddingRight: "30px" }}>
                  Account Manager
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.map((result, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: "#fff",
                      border: "#f3f6f8 dotted 2px",
                    }}
                  >
                    <StyledTableCell
                      align="left"
                      style={{ paddingLeft: "30px" }}
                    >
                      {result.company}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.company_mandarin}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.office_address}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.contact_name}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.department}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.position}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.office_number}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.extension}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.phone}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.email}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.remarks}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.payment_terms}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.shipment_terms}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.type}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ paddingRight: "30px" }}
                    >
                      {result.account_manager}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default GetContact;
