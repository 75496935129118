import React, { useEffect, useState } from "react";
import {
  TextField,
  makeStyles,
  Typography,
  Button,
  styled,
  Box,
  Grid,
  Modal,
} from "@mui/material";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  validateSession,
} from "../utils";
import axios from "axios";
import Notification from "../components/Notification";
import { Autocomplete } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { StyledHeader } from "../components/StyledHeader";
import {
  StyledGreenButton,
  StyledOrangeButton,
  StyledRedButton,
} from "../components/StyledButtons";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { CancelOutlined } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { StyledTextField } from "../components/StyledTextField";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

const StyledBox = styled(Box)`
    width: 200px;
    height: 60px;
    border-color: #cdd2d6;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    // flex-direction: column;
    background-color: #f3f6f8;
    transition-duration: 0.2s;
    :hover {
      background-color: #e0e0e0;
      transition-duration: 0.2s;
      cursor: pointer;
    },
  `;

const ModuleText = styled(Typography)`
  font-family: AirbnbCereal-Book;
  font-size: 0.8em;
  color: #29333e;
  margin-left: 1em;
`;

const CompanyAdmin = () => {
  let navigate = useNavigate();
  const [company, setCompany] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [cid, setCid] = useState("");
  const [openCompany, setOpenCompany] = useState(false);
  const [openCountry, setOpenCountry] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();

    axios
      .get(`${API_GATEWAY_URL}/company`, {
        params: {
          creator: Cookies.get("employee"),
          role: Cookies.get("role", "MANAGER"),
        },
      })
      .then((response) => {
        let options = [];
        for (let item of response.data) {
          options.push(item.english);
        }
        setCompanyOptions(options);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSnackBar = (sev, msg) => {
    setSeverity(sev);
    setMessage(msg);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const deleteCompany = () => {
    axios
      .delete(`${API_GATEWAY_URL}/company`, {
        data: {
          company: company.toUpperCase(),
        },
      })
      .then((resp) => {
        // refresh search
        axios
          .get(`${API_GATEWAY_URL}/company`)
          .then((response) => {
            setCompany("");
            setSelectedCompany("");
            setCompanyOptions(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
        handleSnackBar("success", "Successful Deleted Company");
      })
      .catch(function (error) {
        handleSnackBar("error", "Error processing request, please try again");
      });
  };

  const handleCompanyInputChange = (event, input) => {
    let value = input.toUpperCase();
    setCompany(value);
    // only perform a search if the previous value is different
    if (selectedCompany != value && companyOptions.includes(value)) {
      setSelectedCompany(value);
    }
  };

  const createCountry = async () => {
    await axios
      .post(`${API_GATEWAY_URL}/company/country`, {
        data: {
          country: cid.toUpperCase(),
        },
      })
      .then((response) => {
        if (response.status == 200) {
          handleSnackBar("success", "Add country successful");
          setCid("");
          setOpenCountry(false);
        } else {
          handleSnackBar("error", `Error: ${response.status}`);
        }
      })
      .catch(function (error) {
        handleSnackBar("error", "Error processing request, please try again");
      });
  };

  return (
    <Box sx={{ padding: "0em 1em", fontFamily: "AirbnbCereal-Medium" }}>
      <StyledHeader>
        <AdminPanelSettingsOutlinedIcon /> Company
      </StyledHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          marginTop: "1em",
        }}
      >
        <StyledBox
          sx={{ flex: 1 }}
          onClick={() => {
            setOpenCountry(true);
          }}
        >
          <AddBoxOutlinedIcon fontSize="small" sx={{ color: "#29333e" }} />
          <ModuleText>Add Country</ModuleText>
        </StyledBox>
        <StyledBox
          sx={{ flex: 1, marginLeft: "1em" }}
          onClick={() => {
            setOpenCompany(true);
          }}
        >
          <ApartmentIcon fontSize="small" sx={{ color: "#29333e" }} />
          <ModuleText>Delete Company</ModuleText>
        </StyledBox>
      </Box>
      <Notification
        close={handleCloseSnackbar}
        open={openSnackbar}
        message={message}
        severity={severity}
      />
      <Modal
        open={openCompany}
        onClose={() => {
          setOpenCompany(false);
        }}
      >
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "87vh",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            width: "30%",
            padding: "50px 20px 50px 20px",
            backgroundColor: "#fff",
            border: "none",
            boxShadow: 24,
            "&:focus": {
              border: "none",
            },
          }}
        >
          <StyledHeader sx={{ margin: "0em", padding: "0em" }}>
            Delete Company
          </StyledHeader>
          <Autocomplete
            freeSolo
            options={companyOptions}
            inputValue={company}
            onInputChange={handleCompanyInputChange}
            sx={{
              fontFamily: "AirbnbCereal-Book",
              fontSize: "1em",
              marginTop: "1.5em",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontFamily: "AirbnbCereal-Book",
                    fontSize: "1em",
                  },
                }}
                label="Company Name"
              />
            )}
          />
          <Grid
            container
            lg={12}
            spacing={2}
            sx={{
              marginTop: "1em",
            }}
          >
            <Grid lg={6} item>
              <StyledOrangeButton
                onClick={deleteCompany}
                startIcon={<DeleteOutlineIcon style={{ fontSize: "14px" }} />}
              >
                Delete Company
              </StyledOrangeButton>
            </Grid>
            <Grid lg={6} item>
              <StyledRedButton
                onClick={() => {
                  setOpenCompany(false);
                }}
                startIcon={<CancelOutlined style={{ fontSize: "14px" }} />}
              >
                Close
              </StyledRedButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openCountry}
        onClose={() => {
          setOpenCountry(false);
        }}
      >
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "87vh",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            width: "30%",
            padding: "50px 20px 50px 20px",
            backgroundColor: "#fff",
            border: "none",
            boxShadow: 24,
            "&:focus": {
              border: "none",
            },
          }}
        >
          <StyledHeader sx={{ margin: "0em", padding: "0em" }}>
            Add Country
          </StyledHeader>
          <StyledTextField
            freeSolo
            inputValue={cid}
            onChange={(e) => setCid(e.target.value)}
            sx={{
              fontFamily: "AirbnbCereal-Book",
              fontSize: "1em",
              marginTop: "1.5em",
            }}
            label="Country name."
          />
          <Grid
            container
            lg={12}
            spacing={2}
            sx={{
              marginTop: "1em",
            }}
          >
            <Grid lg={6} item>
              <StyledGreenButton
                onClick={createCountry}
                startIcon={<AddBoxOutlinedIcon style={{ fontSize: "14px" }} />}
              >
                Add Country
              </StyledGreenButton>
            </Grid>
            <Grid lg={6} item>
              <StyledRedButton
                onClick={() => {
                  setOpenCountry(false);
                }}
                startIcon={<CancelOutlined style={{ fontSize: "14px" }} />}
              >
                Close
              </StyledRedButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default CompanyAdmin;
