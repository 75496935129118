import { Button, styled } from "@mui/material";

export const StyledRedButton = styled(Button)`
  width: 100%;
  margin: 0px;
  padding: 10px 20px;
  border-radius: 8px;
  font-family: AirbnbCereal-Medium;
  font-size: 12px;
  background-color: #fdecea;
  color: #f44336;
  boxshadow: none;
  :hover {
    background-color: #fbc6c2;
  }
`;

export const StyledGreenButton = styled(Button)`
  width: 100%;
  margin: 0px;
  padding: 10px 20px;
  border-radius: 8px;
  font-family: AirbnbCereal-Medium;
  font-size: 12px;
  background-color: #e1f6e6;
  color: #3b9c6c;
  boxShadow: none;
  :hover {
    background-color: #b4e8c0;
  },
`;

export const StyledOrangeButton = styled(Button)`
  width: 100%;
  margin: 0px;
  padding: 10px 20px;
  border-radius: 8px;
  font-family: AirbnbCereal-Medium;
  font-size: 12px;
  background-color: #fdf0e8;
  color: #f06d22;
  boxShadow: none;
  :hover {
    background-color: #fce1d2;
  },
`;

export const StyledBlueButton = styled(Button)`
  width: 100%;
  margin: 0px;
  padding: 10px 20px;
  border-radius: 8px;
  font-family: AirbnbCereal-Medium;
  font-size: 12px;
  background-color: #f9f9ff;
  color: #000099;
  boxShadow: none;
  :hover {
    background-color: #f4f4ff;
  },
`;

export const StyledGrayButton = styled(Button)`
  width: 100%;
  margin: 0px;
  padding: 10px 20px;
  border-radius: 8px;
  font-family: AirbnbCereal-Medium;
  font-size: 12px;
  background-color: #f4f4f4;
  color: #5b5b5b;
  boxShadow: none;
  :hover {
    background-color: #e0e0e0;
  },
`;

export const StyledDropDownButton = styled(Button)`
  width: 100%;
  margin: 0px;
  padding: 10px 20px;
  background-color: #fff;
  color: #252b32;
  boxShadow: none;
  :hover {
    background-color: #f4f4f4;
  },
`;
