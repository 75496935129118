import React, { useEffect, useState } from "react";
import {
  TextField,
  makeStyles,
  Typography,
  Button,
  styled,
  Box,
  Grid,
  Modal,
} from "@mui/material";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  roles,
  validateSession,
} from "../utils";
import axios from "axios";
import Notification from "../components/Notification";
import { Autocomplete } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { StyledHeader } from "../components/StyledHeader";
import {
  StyledGreenButton,
  StyledOrangeButton,
  StyledRedButton,
} from "../components/StyledButtons";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";

const StyledBox = styled(Box)`
    // width: 200px;
    height: 60px;
    border-color: #cdd2d6;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #f3f6f8;
    transition-duration: 0.2s;
    :hover {
      background-color: #e0e0e0;
      transition-duration: 0.2s;
      cursor: pointer;
    },
  `;

const ModuleText = styled(Typography)`
  font-family: AirbnbCereal-Book;
  font-size: 0.8em;
  color: #29333e;
  margin-left: 1em;
`;

const Admin = () => {
  let navigate = useNavigate();
  const [company, setCompany] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");

  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  const [qid, setQid] = useState("");
  const [qidOptions, setQidOptions] = useState([]);
  const [selectedQid, setSelectedQid] = useState("");

  const [openCompany, setOpenCompany] = useState(false);
  const [openQuotation, setOpenQuotation] = useState(false);

  const [role, setRole] = useState(Cookies.get("role", "MANAGER"));
  const [employee, setEmployee] = useState(Cookies.get("employee", ""));

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();

    // perform company search here & set
    axios
      .get(`${API_GATEWAY_URL}/company`, {
        params: {
          creator: employee,
          role: role,
        },
      })
      .then((response) => {
        let options = [];
        for (let item of response.data) {
          options.push(item.english);
        }
        setCompanyOptions(options);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${API_GATEWAY_URL}/quotation`, {
        params: {
          creator: employee,
          role: role,
        },
      })
      .then((response) => {
        response.data.sort((a, b) => {
          if (a.quotation_id === b.quotation_id) {
            return a.line_number - b.line_number;
          }
          return (
            parseInt(b.quotation_id.split("EQ-")[1]) -
            parseInt(a.quotation_id.split("EQ-")[1])
          );
        });

        const seen = new Set();
        const filtered_data = response.data.filter((obj) => {
          const duplicate = seen.has(obj["quotation_id"]);
          seen.add(obj["quotation_id"]);
          return !duplicate;
        });
        setQidOptions(filtered_data.map((item) => item.quotation_id));
      })
      .catch((error) => {
        console.log(error);
        // alert(error);
      });
  }, []);

  const handleSnackBar = (sev, msg) => {
    setSeverity(sev);
    setMessage(msg);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const deleteCompany = () => {
    axios
      .delete(`${API_GATEWAY_URL}/company`, {
        data: {
          company: company.toUpperCase(),
        },
      })
      .then((resp) => {
        // refresh search
        axios
          .get(`${API_GATEWAY_URL}/company`)
          .then((response) => {
            setCompany("");
            setSelectedCompany("");
            setCompanyOptions(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
        handleSnackBar("success", "Successful Deleted Company");
      })
      .catch(function (error) {
        handleSnackBar("error", "Error processing request, please try again");
      });
  };

  const handleCompanyInputChange = (event, input) => {
    let value = input.toUpperCase();
    setCompany(value);
    // only perform a search if the previous value is different
    if (selectedCompany != value && companyOptions.includes(value)) {
      setSelectedCompany(value);
    }
  };

  const handleQuotationInputChange = (event, input) => {
    let value = input.toUpperCase();
    setQid(value);
    // only perform a search if the previous value is different
    if (selectedQid != value && qidOptions.includes(value)) {
      setSelectedQid(value);
    }
  };

  const deleteQuotation = () => {
    axios
      .delete(`${API_GATEWAY_URL}/quotation`, {
        data: {
          qid: qid.toUpperCase(),
        },
      })
      .then((resp) => {
        // refresh search
        axios
          .get(`${API_GATEWAY_URL}/quotation`)
          .then((response) => {
            setQid("");
            setSelectedQid("");

            response.data.sort((a, b) => {
              if (a.quotation_id === b.quotation_id) {
                return a.line_number - b.line_number;
              }
              return (
                parseInt(b.quotation_id.split("EQ-")[1]) -
                parseInt(a.quotation_id.split("EQ-")[1])
              );
            });

            const seen = new Set();
            const filtered_data = response.data.filter((obj) => {
              const duplicate = seen.has(obj["quotation_id"]);
              seen.add(obj["quotation_id"]);
              return !duplicate;
            });
            setQidOptions(filtered_data.map((item) => item.quotation_id));
          })
          .catch((error) => {
            console.log(error);
          });
        handleSnackBar(
          "success",
          "Successful Deleted Quotation & Related line items"
        );
      })
      .catch(function (error) {
        handleSnackBar("error", "Error processing request, please try again");
      });
  };

  return (
    <Box sx={{ padding: "0em 1em", fontFamily: "AirbnbCereal-Medium" }}>
      <StyledHeader>
        <AdminPanelSettingsOutlinedIcon /> Admin
      </StyledHeader>
      <Grid container spacing={2}>
        <Grid item lg={2}>
          <StyledBox
            onClick={() => {
              navigate("/quotation-admin");
            }}
          >
            <AddchartOutlinedIcon fontSize="small" sx={{ color: "#29333e" }} />
            <ModuleText>Pending Approvals</ModuleText>
          </StyledBox>
        </Grid>
        <Grid item lg={2}>
          <StyledBox
            onClick={() => {
              navigate("/supplier-admin");
            }}
          >
            <ReceiptLongOutlinedIcon
              fontSize="small"
              sx={{ color: "#29333e" }}
            />
            <ModuleText>Supplier</ModuleText>
          </StyledBox>
        </Grid>
        <Grid item lg={2}>
          <StyledBox
            onClick={() => {
              navigate("/stock-list");
            }}
            sx={{ width: "100%" }}
          >
            <InventoryOutlinedIcon fontSize="small" sx={{ color: "#29333e" }} />
            <ModuleText>Stock List</ModuleText>
          </StyledBox>
        </Grid>
        {role == "ADMIN" && (
          <>
            <Grid item lg={2}>
              <StyledBox
                onClick={() => {
                  navigate("/company-admin");
                }}
                sx={{ width: "100%" }}
              >
                <CorporateFareOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#29333e" }}
                />
                <ModuleText>Company</ModuleText>
              </StyledBox>
            </Grid>
            <Grid item lg={2}>
              <StyledBox
                onClick={() => {
                  setOpenQuotation(true);
                }}
                sx={{ width: "100%" }}
              >
                <RequestQuoteOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#29333e" }}
                />
                <ModuleText>Delete Quotation</ModuleText>
              </StyledBox>
            </Grid>
            <Grid item lg={2}>
              <StyledBox
                onClick={() => {
                  navigate("/staff-management");
                }}
              >
                <PersonSearchOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#29333e" }}
                />
                <ModuleText>Staff Management</ModuleText>
              </StyledBox>
            </Grid>
            {/* <Grid item lg={2}>
              <StyledBox
                onClick={() => {
                  navigate("/approved-price-list");
                }}
              >
                <AddTaskOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#29333e" }}
                />
                <ModuleText>Approved Price List</ModuleText>
              </StyledBox>
            </Grid> */}
          </>
        )}
      </Grid>
      <Notification
        close={handleCloseSnackbar}
        open={openSnackbar}
        message={message}
        severity={severity}
      />
      <Modal
        open={openCompany}
        onClose={() => {
          setOpenCompany(false);
        }}
      >
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "87vh",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            width: "30%",
            padding: "50px 20px 50px 20px",
            backgroundColor: "#fff",
            border: "none",
            boxShadow: 24,
            "&:focus": {
              border: "none",
            },
          }}
        >
          <StyledHeader sx={{ margin: "0em", padding: "0em" }}>
            Delete Company
          </StyledHeader>
          <Autocomplete
            freeSolo
            options={companyOptions}
            inputValue={company}
            onInputChange={handleCompanyInputChange}
            sx={{
              fontFamily: "AirbnbCereal-Book",
              fontSize: "1em",
              marginTop: "1.5em",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontFamily: "AirbnbCereal-Book",
                    fontSize: "1em",
                  },
                }}
                label="Company Name"
              />
            )}
          />
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "1em",
            }}
          >
            <Grid lg={6} item>
              <StyledOrangeButton
                onClick={deleteCompany}
                startIcon={<DeleteOutlineIcon style={{ fontSize: "14px" }} />}
              >
                Delete Company
              </StyledOrangeButton>
            </Grid>
            <Grid lg={6} item>
              <StyledRedButton
                onClick={() => {
                  setOpenCompany(false);
                }}
                startIcon={<CancelOutlined style={{ fontSize: "14px" }} />}
              >
                Close
              </StyledRedButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openQuotation}
        onClose={() => {
          setOpenQuotation(false);
        }}
      >
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "87vh",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            width: "30%",
            padding: "50px 20px 50px 20px",
            backgroundColor: "#fff",
            border: "none",
            boxShadow: 24,
            "&:focus": {
              border: "none",
            },
          }}
        >
          <StyledHeader sx={{ margin: "0em", padding: "0em" }}>
            Delete Quotation
          </StyledHeader>
          <Autocomplete
            freeSolo
            options={qidOptions}
            inputValue={qid}
            onInputChange={handleQuotationInputChange}
            sx={{
              fontFamily: "AirbnbCereal-Book",
              fontSize: "1em",
              marginTop: "1.5em",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontFamily: "AirbnbCereal-Book",
                    fontSize: "1em",
                  },
                }}
                label="Quotation No."
              />
            )}
          />
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "1em",
            }}
          >
            <Grid lg={6} item>
              <StyledOrangeButton
                onClick={deleteQuotation}
                startIcon={<DeleteOutlineIcon style={{ fontSize: "14px" }} />}
              >
                Delete Quotation
              </StyledOrangeButton>
            </Grid>
            <Grid lg={6} item>
              <StyledRedButton
                onClick={() => {
                  setOpenCompany(false);
                }}
                startIcon={<CancelOutlined style={{ fontSize: "14px" }} />}
              >
                Close
              </StyledRedButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default Admin;
