import React from "react";
import CreateQuotation from "./views/CreateQuotation";
import Company from "./views/Company";
import GetQuotation from "./views/GetQuotation";
import Contact from "./views/Contact";
import Admin from "./views/Admin";
import GetContact from "./views/GetContacts";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import StockCheck from "./views/StockCheck";
import SupplierPriceList from "./views/SupplierPriceList";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import TocOutlinedIcon from "@mui/icons-material/TocOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

const routes = [
  {
    path: "/admin",
    name: "Admin",
    icon: <AdminPanelSettingsOutlinedIcon sx={{ fontSize: "17px" }} />,
    component: Admin,
  },
  {
    path: "/company",
    name: "Company",
    icon: <DomainAddOutlinedIcon sx={{ fontSize: "17px" }} />,
    component: Company,
  },
  {
    path: "/contact-person",
    name: "Contact Person",
    icon: <BadgeOutlinedIcon sx={{ fontSize: "17px" }} />,
    component: Contact,
  },
  {
    path: "/contact-person/get",
    name: "Company Data",
    icon: <GroupsOutlinedIcon sx={{ fontSize: "17px" }} />,
    component: GetContact,
  },
  // {
  //   path: "/price-list",
  //   name: "Price List",
  //   icon: <DocumentScannerOutlinedIcon />,
  //   component: PriceList,
  // },
  {
    path: "/stock-check",
    name: "Stock",
    icon: <Inventory2OutlinedIcon sx={{ fontSize: "17px" }} />,
    component: StockCheck,
  },
  {
    path: "/supplier/price-list",
    name: "Supplier Price List",
    icon: <TocOutlinedIcon sx={{ fontSize: "17px" }} />,
    component: SupplierPriceList,
  },
  {
    path: "/quotation/create",
    name: "Create Quotation",
    icon: <RequestQuoteOutlinedIcon sx={{ fontSize: "17px" }} />,
    component: CreateQuotation,
  },
  {
    path: "/quotation/get",
    name: "Quotation Report",
    icon: <AssessmentOutlinedIcon sx={{ fontSize: "17px" }} />,
    component: GetQuotation,
  },
];

export default routes;
